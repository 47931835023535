














































































import { vxm } from "@/store";
import { Component, Vue, Prop } from "vue-property-decorator";
import {CaseApiFactory, CaseDTO, Configuration, PanicDTO} from "@shared_vue/openapi/caseapi";

@Component
export default class InstantEmergency extends Vue {
  private ui = vxm.ui;
  private caseApi = CaseApiFactory(<Configuration>{basePath:process.env.VUE_APP_CASE_API_URL}, process.env.VUE_APP_CASE_API_URL)
  private auth = vxm.auth;
  private handleSwitch(e: boolean, type: number) {
    if (e) {
      let text = `Creating Case (${type}). A dispatcher will contact you shortly.`;
      alert(text);
      let newCase = <PanicDTO>{};
      newCase.caseTypeId=type;
      newCase.profileGuid = this.auth.usr!.uid;
      newCase.sourceIdentifier = "PanicSwitch"
      this.caseApi.casePanic(newCase);
    }
  }
}
