











import Vue from 'vue';
import Component from 'vue-class-component';
import axios from 'axios';

@Component
export default class GlobalSearch extends Vue {
  inputText = '';
  suggestions: Array<{ url: string; suggestion: string }> = [];

  async fetchSuggestions() {
    if (this.inputText.length > 1) {
      try {
        const response = await axios.get(`https://apicase.gen11project.com/case/search/${this.inputText}`);
        this.suggestions = response.data;
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        this.suggestions = [];
      }
    } else {
      this.suggestions = [];
    }
  }

  selectSuggestion(item: { url: string; suggestion: string }) {
    window.location.href = item.url; // Or use Vue Router if you are using it
  }
}
