import { render, staticRenderFns } from "./Badges.vue?vue&type=template&id=75c83c79&scoped=true"
import script from "./Badges.vue?vue&type=script&lang=ts"
export * from "./Badges.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75c83c79",
  null
  
)

export default component.exports