









import { vxm } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import Badges from "@/views/widgets/Badges.vue";
import Search from "@/views/widgets/Search.vue";
import TeamOnDuty from "@/views/widgets/TeamOnDuty.vue";
import MyCases from "@/views/widgets/MyCases.vue";
import HowTo from "@/views/widgets/HowTo.vue";

@Component({
  components: {
    Badges,
    Search,
    TeamOnDuty,
    MyCases,
    HowTo
  },
})
export default class DashAsideRight extends Vue {
  private ui = vxm.ui;

  private toggleMin() {
    this.ui.rightMinimize = !this.ui.rightMinimize;
  }
}
