









































































































































import { vxm } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase";
import Cookies from "js-cookie";

@Component({
  components: {},
})
export default class DashHeader extends Vue {
  private ui = vxm.ui;
  private shop = vxm.shop;
  private auth = vxm.auth;
  private darkMode: boolean = false;
  private publicPath = process.env.VUE_APP_STATIC_DIR;

  
  
  private toggleDarkMode() {
    this.ui.toggleDarkMode();
    document.body.classList.toggle("c-dark-theme");
  }
  
  get layoutOptions(){
    return this.ui.layoutOptions;
  }
  private changelayout(){
    //@ts-ignore
    console.log(this.$refs.layoutselector.state);
    //@ts-ignore
    this.ui.setLayout(this.$refs.layoutselector.state);
  }

  private logmeout() {
    console.log('goodbye')
    // Cookies.remove('__session');
    firebase
      .auth()
      .signOut()
      .then(() => {
        vxm.ui.RegisterState = 1;
        this.$router.push("/");
      })
      .catch((error) => {
        // An error happened.
      });
  }

  get name() {
    return this.auth.usr?.displayName;
  }
}
